import SignInOptions from 'features/signInOption';
import { useLocation } from 'react-router-dom';

const SigninOptionsPages = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let obj = {};
  // object.fromEntries <== doesnt support in all browsers
  for (const [key, value] of searchParams) {
    obj = {
      ...obj,
      [key]: value,
    };
  }
  return <SignInOptions query={obj} />;
};
export default SigninOptionsPages;
