import { Form, Button, Spin } from 'antd';
import Card from 'component/ui/card';
import SigninOptionFormElement from './SignOptionFormElement';
import {
  ENTER_VERIFICATION_CODE,
  LOGIN_PAGE,
  VERIFY,
  RESEND_CODE,
  BACK,
  NEXT,
} from 'constants';
import ModalForm from './ModalForm';
import VerifyCodeForm from './VerfiyCodeForm';
import WorkSpaceForm from './WorkSpaceForm';
import componyIcon from 'assets/images/companyIcon.svg';
import unlockSVG from 'assets/images/unlock.svg';

const SignInOptionsForm = ({
  query,
  data,
  onSetRecovery,
  recovery,
  onSubmit,
  loading,
  showSelectWorkspace,
  workSpaceId,
  modalRef,
  refCaptcha,
  counterRef,
  modalToggle,
  onCloseModal,
  onSubmitCode,
  onResendForm,
  needCaptcha,
  workSpaces,
  onSelectWorkSpace,
  getCodeLoading,
  onChangePage,
  modalLoading,
  redirectLoading,
}) => {
  const [form] = Form.useForm();
  return (
    <Spin spinning={loading || getCodeLoading || redirectLoading}>
      <Card
        title={
          workSpaces?.length > 0
            ? LOGIN_PAGE.CHOOSE_TO_LOGIN
            : LOGIN_PAGE.LOGIN_OPTION
        }
        query={query}>
        <Form
          layout="vertical"
          form={form}
          style={{ display: 'flex', flexDirection: 'column' }}
          name="recoveryPassword"
          onFinish={onSubmit}
          initialValues={{
            type: '2',
          }}
          scrollToFirstError>
          {workSpaces?.length > 0 ? (
            <WorkSpaceForm
              workSpaces={workSpaces.map(({ _id, name, icon }) => ({
                id: _id,
                title: name,
                icon: icon || componyIcon,
              }))}
              onSelectWorkSpace={onSelectWorkSpace}
              workSpaceId={workSpaceId}
            />
          ) : (
            <SigninOptionFormElement
              onSetRecovery={onSetRecovery}
              state={data}
              form={form}
            />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 'auto',
            }}>
            {!showSelectWorkspace && (
              <Button onClick={onChangePage}>{BACK}</Button>
            )}
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={showSelectWorkspace && !workSpaceId}
              style={{ display: 'block', marginLeft: 'auto' }}>
              {NEXT}
            </Button>
          </div>
        </Form>
        {modalToggle && (
          <ModalForm
            counterRef={counterRef}
            ref={modalRef}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img alt="" style={{ marginRight: '0.5rem' }} src={unlockSVG} />
                <span>{ENTER_VERIFICATION_CODE}</span>
              </div>
            }
            visible={modalToggle}
            submitForm={onSubmitCode}
            onCancel={onCloseModal}
            buttonTitle={VERIFY}
            hasTimer={true}
            finalMessage={RESEND_CODE}
            onTimerFinish={onResendForm}
            maskClosable={false}
            modalLoading={modalLoading}
            requiredMark={false}>
            <VerifyCodeForm
              needCaptcha={needCaptcha}
              validateCodeForm={modalRef}
              refCaptcha={refCaptcha}
              value={recovery}
              state={data}
            />
          </ModalForm>
        )}
      </Card>
    </Spin>
  );
};

export default SignInOptionsForm;
