import { Card as AntCard, Typography } from 'antd';
import CardTitle from '../cardTitle';

const Card = ({ children, title, query }) => {
  return (
    <AntCard
      title={<CardTitle title={title} query={query} />}
      bordered={true}
      className={'register-card-layout'}
      style={{ borderRadius: '16px' }}
      bodyStyle={{ padding: '0' }}>
      <div style={{ padding: '24px' }}>{children}</div>
    </AntCard>
  );
};

export default Card;
