import { Typography, Button } from 'antd';
import { BACK } from 'constants';
import { LOGIN_PAGE, TRY_AGAIN } from 'constants';

const PasswordLessForm = ({ loading, gotSignInOptions, prev }) => {
  const { Text } = Typography;
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '4px',
      }}>
      {loading ? (
        <Text>{LOGIN_PAGE.SECURITY_KEY_TEXT}</Text>
      ) : (
        <Text className="error-color">{LOGIN_PAGE.SECURITY_KEY_ERROR}</Text>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Button onClick={prev}>{BACK}</Button>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button onClick={() => gotSignInOptions()}>
            {LOGIN_PAGE.OTHER_WAYS_OPTIONS}
          </Button>
          <Button type="primary" htmlType="submit" disabled={loading}>
            {TRY_AGAIN}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PasswordLessForm;
