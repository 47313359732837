import React, { useState } from 'react';
import { Button, Statistic } from 'antd';

const { Countdown } = Statistic;
const CountDownTimer = React.forwardRef(function (
  { minutes, onFinish, style, finalMessage, spin, buttonTitle },
  ref
) {
  const defaultTime = Date.now() + minutes * 60 * 1000;
  const [time, setTime] = useState(defaultTime);
  const [finished, setFinished] = React.useState(false);
  const onFinishCounter = () => {
    setFinished(true);
  };
  const resetComponent = () => {
    onFinish && onFinish();
    setFinished(false);
  };
  const updateTime = () => {
    setTime(defaultTime);
  };
  React.useImperativeHandle(ref, () => ({
    updateTime,
    setFinished,
  }));
  return (
    <>
      <div style={style}>
        {finished ? (
          <Button
            style={{ display: 'inline', margin: '0', padding: '0' }}
            type="link"
            size="large"
            onClick={resetComponent}>
            {finalMessage}
          </Button>
        ) : (
          <Countdown
            className="count-down--1"
            value={time}
            onFinish={onFinishCounter}
            format="mm:ss"
          />
        )}
      </div>
      <Button
        style={{ display: 'block', marginLeft: 'auto' }}
        type="primary"
        loading={spin}
        htmlType="submit"
        disabled={finished}>
        {buttonTitle}
      </Button>
    </>
  );
});

export default CountDownTimer;
