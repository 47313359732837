import { useLocation } from 'react-router-dom';
import SignIn from 'features/signIn';

const SignInPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let obj = {};
  // object.fromEntries <== doesnt support in all browsers
  for (const [key, value] of searchParams) {
    obj = {
      ...obj,
      [key]: value,
    };
  }

  return <SignIn query={obj} />;
};

export default SignInPage;
