import { useRef, useState } from 'react';
import { Form, Spin } from 'antd';
import logo from 'assets/images/logo.png';
import StepOne from './StepOne';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import StepTwo from './StepTwo';
import Card from 'component/ui/card';
import PasswordLessForm from './PasswordLessForm';
import { SIGN_IN_OPTIONS, LOGIN_PAGE } from 'constants';

const SignInForm = ({
  title,
  loading,
  onFinish,
  step,
  current,
  next,
  prev,
  refCaptcha,
  submitLoading,
  query,
  onSignInSecondButton,
  isMac,
  isMobile,
  passwordLessLoading,
  emailSubmitLoading,
}) => {
  const [form] = Form.useForm();
  const nodeRef = useRef();
  const [animClass, setAnimClass] = useState('right');
  const renderTitle = () => {
    if (step !== 0) {
      return LOGIN_PAGE.LOGIN_WITH_SECURITY_KEY;
    } else if (step === 0 && current === 1) {
      return SIGN_IN_OPTIONS;
    } else {
      return title;
    }
  };
  return (
    <Spin spinning={loading || submitLoading || passwordLessLoading}>
      <Card title={renderTitle()} imgUrl={logo} query={query}>
        <Form
          layout="vertical"
          form={form}
          name="login"
          onFinish={onFinish}
          scrollToFirstError
          requiredMark={false}
          style={{
            display: 'flex',
            flexDirection: 'column',
            WebkitFlexDirection: 'column',
            MozFlexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '220px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          initialValues={{ email: '' }}>
          <SwitchTransition>
            <CSSTransition
              key={current}
              nodeRef={nodeRef}
              classNames={animClass}
              timeout={{ enter: 800, exit: 500 }}>
              {step !== 0 ? (
                <PasswordLessForm
                  gotSignInOptions={next}
                  loading={passwordLessLoading}
                  prev={prev}
                />
              ) : current === 0 ? (
                <StepOne
                  query={query}
                  isMobile={isMobile}
                  isMac={isMac}
                  animateRef={nodeRef}
                  loading={loading}
                  next={next}
                  onSignInSecondButton={onSignInSecondButton}
                />
              ) : (
                <div ref={nodeRef} style={{ minHeight: '220px' }}>
                  <StepTwo
                    form={form}
                    refCaptcha={refCaptcha}
                    loading={emailSubmitLoading}
                    prev={prev}
                  />
                </div>
              )}
            </CSSTransition>
          </SwitchTransition>
        </Form>
      </Card>
    </Spin>
  );
};

export default SignInForm;
