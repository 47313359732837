import { useMutation } from 'react-query';
import { putAssertion } from '../api';

const usePutAssertion = (query) => {
  const { mutateAsync, isLoading, error, data } = useMutation({
    mutationFn: (data) => putAssertion(data, query),
  });

  return {
    mutateAsync,
    isLoading,
    error,
    data,
  };
};

export default usePutAssertion;
