import { useMutation } from 'react-query';
import { resendCode } from '../api';

const useResendCode = (query, counterRef) => {
  const { data, mutate, isLoading, error } = useMutation({
    mutationFn: (data) => resendCode(data, query),
    onSuccess: () => {
      counterRef.current.updateTime();
    },
  });

  return {
    data,
    mutate,
    isLoading,
    error,
  };
};

export default useResendCode;
