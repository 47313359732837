import { useState } from 'react';
const { useMutation } = require('react-query');
const { selectWorkSpace } = require('../api');

const useSelectWorkSpace = (query) => {
  const [redirectLoading, setRedirectLoading] = useState(false);
  const { data, isLoading, mutate } = useMutation({
    mutationFn: (data) => selectWorkSpace(data, query),
    onSuccess: (data) => {
      if (data?.redirectURL) {
        setRedirectLoading(true);
        window.location.replace(data.redirectURL);
      }
    },
  });
  return {
    workSpaceLoading: isLoading,
    workSpaceData: data,
    workSpaceMutate: mutate,
    redirectLoading,
  };
};
export default useSelectWorkSpace;
