import { useMutation } from 'react-query';
import { deleteLogin } from '../api';

const useDeleteLogin = () => {
  const { mutate } = useMutation({
    mutationFn: () => deleteLogin(),
  });

  return {
    deleteMutate: mutate,
  };
};

export default useDeleteLogin;
