import { Form } from 'antd';
import { SELECT_EMAIL_OR_SMS } from 'constants';
import CardBoxGroup from 'component/ui/cardBoxGroup';

const SigninOptionFormElement = ({ state, onSetRecovery, form }) => {
  const { email, hasPhoneNumber, phoneX } = state || {};

  const onHandleChange = (id) => {
    onSetRecovery(id);
    form.setFieldValue('type', id);
  };

  const sendCodeOptions = [
    {
      title: `Email: ${email ?? ''}`,
      id: '2',
    },
    {
      title: `Phone: ${hasPhoneNumber ? phoneX : ''}`,
      id: '1',
      disabled: !hasPhoneNumber,
    },
  ];
  return (
    <div>
      <Form.Item
        style={{ marginBottom: '2rem', marginTop: '1rem' }}
        name="type"
        label={<p>{SELECT_EMAIL_OR_SMS}</p>}>
        <CardBoxGroup
          onSelect={onHandleChange}
          options={sendCodeOptions}
          defaultValue={'2'}
        />
      </Form.Item>
    </div>
  );
};

export default SigninOptionFormElement;
