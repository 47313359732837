import { Form, Input, Button } from 'antd';
import {
  EMAIL,
  EMAIL_IS_NOT_VALID,
  RECAPTHCA_IS_REQUIRED,
  SECURITY_CHECK,
  VERIFYING,
  NEXT,
  BACK,
} from 'constants';
import CaptchaForm from 'component/form/captcha';
import * as config from 'config/config';

const LoginForm = ({ refCaptcha, form, loading, prev }) => {
  const handleVerificationSuccess = (token, ekey) => {
    form.setFieldsValue({ captcha: token });
  };
  return (
    <div
      style={{
        minHeight: '220px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <Form.Item
        name="email"
        label={<span className="required">{EMAIL}</span>}
        rules={[
          {
            required: true,
            type: 'email',
            message: EMAIL_IS_NOT_VALID,
          },
        ]}>
        <Input
          autoFocus={true}
          autoComplete="on"
          autoCorrect="false"
          autoCapitalize="off"
          spellCheck="false"
          placeholder={'Your@company.com'}
        />
      </Form.Item>
      {!['dev'].includes(config.ENVIRONMENT) && (
        <Form.Item
          name="captcha"
          label={SECURITY_CHECK}
          rules={[
            {
              required: true,
              message: RECAPTHCA_IS_REQUIRED,
            },
          ]}>
          <CaptchaForm
            handleVerificationSuccess={handleVerificationSuccess}
            refCaptcha={refCaptcha}
          />
        </Form.Item>
      )}
      <div
        style={{
          marginTop: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Button onClick={prev} disabled={loading}>
          {BACK}
        </Button>

        <Button type="primary" loading={loading} htmlType="submit">
          {loading ? VERIFYING : NEXT}
        </Button>
      </div>
    </div>
  );
};

export default LoginForm;
