import { errorNotif } from 'component/ui/notif';
import { WEB_AUTHN_NOT_PRESENTED } from 'constants';
import * as config from 'config/config';

export const requestLoginPasswordless = (challenge, asyncMutate, callback) => {
  getAssertion({ result: challenge }, asyncMutate)
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      callback(error);
      // if (error?.response?.data?.message) errorNotif(error?.response?.data?.message);
      // else errorNotif(error?.message)
    });
};

function getAssertion(response, asyncMutate) {
  if (typeof window.PublicKeyCredential === 'undefined')
    return Promise.reject(WEB_AUTHN_NOT_PRESENTED);

  const challenge = stringToArrayBuffer(response.result);
  const userAgent = window.navigator.userAgent;
  const isKioware = /KioWare/i.test(userAgent);
  const getAssertionOptions = {
    rpId: config.AUTO_ENROLLMENT_RP_ID || 'idmelon.com',
    timeout: 90000,
    challenge: challenge,
    // KioWare internal browser does not support empty allowCredentials. so, we will
    // send a dummy credentialId and ignore it.
    allowCredentials: isKioware
      ? [
          {
            id: new TextEncoder().encode('00000000000000000000000000000001'),
            type: 'public-key',
          },
        ]
      : [],
    userVerification: 'required',
    extensions: {},
  };

  return navigator.credentials
    .get({
      publicKey: getAssertionOptions,
    })
    .then((assertion) => {
      /** @type {EncodedAssertionResponse} */
      var credential = {
        id: base64encode(assertion.rawId),
        clientDataJSON: arrayBufferToString(assertion.response.clientDataJSON),
        userHandle: base64encode(assertion.response.userHandle),
        signature: base64encode(assertion.response.signature),
        authenticatorData: base64encode(assertion.response.authenticatorData),
        metadata: {
          rpId: getAssertionOptions.rpId,
        },
      };
      return asyncMutate(credential);
    })
    .then((response) => {
      return response;
    })
    .then((response) => {
      if (response.error) {
        return Promise.reject(response.error);
      } else {
        return Promise.resolve(response);
      }
    });
}

/**
 * Helper: Base64 encodes an array buffer
 */
function base64encode(arrayBuffer) {
  if (!arrayBuffer || arrayBuffer.byteLength == 0) return undefined;

  return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)));
}

/**
 * Helper: Converts an array buffer to a UTF-8 string
 */
function arrayBufferToString(arrayBuffer) {
  return String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
}

/**
 * Helper: Converts a string to an ArrayBuffer
 */
export const stringToArrayBuffer = (str) => {
  return Uint8Array.from(str, (c) => c.charCodeAt(0)).buffer;
};
