import { Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Main from 'component/main';
import SignOptionsPage from 'pages/siginOptions';
import SignInPage from 'pages/home';
const router = createBrowserRouter([
  {
    path: '/',
    exact: true,
    element: (
      <Suspense>
        <Main />
      </Suspense>
    ),
    children: [
      {
        index: true,
        exact: true,
        element: (
          <Suspense>
            <SignInPage />
          </Suspense>
        ),
      },
      {
        path: '/signinOptions',
        exact: true,
        element: (
          <Suspense>
            <SignOptionsPage />
          </Suspense>
        ),
      },
    ],
  },
]);

export default router;
