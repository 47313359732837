import UAParser from 'ua-parser-js';
import isMobile from 'is-mobile';

const useUserAgent = () => {
  const userAgent = window.navigator.userAgent;
  const isMobileDevice = isMobile({ ua: userAgent });
  let isiPad = /iPad/i.test(userAgent);
  let isMac = /Macintosh/i.test(userAgent);
  const parser = new UAParser();
  const result = parser.setUA(userAgent).getResult();
  let browserKey = 'not_detected';

  if (isMobileDevice || isiPad || isMac) {
    const browserName = result.browser.name.toLowerCase();
    if (/safari/i.test(browserName)) {
      browserKey = 'safari';
    } else if (/chrome/i.test(browserName)) {
      browserKey = 'chrome';
    } else if (/firefox/i.test(browserName)) {
      browserKey = 'firefox';
    }
  }

  if (browserKey !== 'safari' && isMac) {
    isMac = false;
  }

  if (userAgent.includes('OS 17')) {
    isMac = true;
  }

  return {
    isMobile: isMobileDevice || isiPad,
    browser: browserKey,
    isMac,
  };
};

export default useUserAgent;
