import { notification } from 'antd';
import { createContext, useContext } from 'react';
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { SUCCESS, WARNING, ERROR } from 'constants';

const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const showNotif = () => {
    const notifs = document?.querySelectorAll('.ant-notification');
    notifs?.forEach((notif) => {
      notif.style.visibility = 'visible';
    });
  };
  const hideNotif = () => {
    const notifs = document?.querySelectorAll('.ant-notification');
    notifs?.forEach((notif) => {
      notif.style.visibility = 'hidden';
    });
  };
  const [api, contextHolder] = notification.useNotification({
    placement: 'topRight',
    top: 30,
    right: 30,
    duration: 20,
  });
  const successNotif = (message) => {
    api.success({
      message: SUCCESS,
      description: message || SUCCESS,
      duration: 20,
      key: 'success',
      style: { background: '#E5FFF0' },
      icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
    });
  };

  const resetErrorNotif = (url) => {
    notification.close(`error-${url}`);
  };

  const errorNotif = (message, close, url) => {
    if (close) notification.close('error');
    else {
      showNotif();
      api.error({
        message: ERROR,
        description: message || ERROR,
        duration: 20,
        key: url ? `error-${url}` : 'error',
        style: { background: '#FFE5E7' },
        btn: (
          <div
            className="ant-notification-notice-close"
            style={{ cursor: 'pointer' }}
            onClickCapture={hideNotif}
            onClick={hideNotif}>
            <CloseOutlined />
          </div>
        ),
        icon: <CloseCircleOutlined style={{ color: '#ff4d4f' }} />,
        closeIcon: false,
        onClose: () => api.destroy(),
      });
    }
  };

  const warningNotif = (message) => {
    api.warning({
      message: WARNING,
      description: message || WARNING,
      duration: 20,
      key: 'warning',
      style: { background: '#FFF4E5' },
    });
  };
  return (
    <NotificationContext.Provider
      value={{ successNotif, resetErrorNotif, errorNotif, warningNotif }}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifContext = () => useContext(NotificationContext);
