import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'antd';
import CountDownTimer from './CountDownTimer';
import { RESEND } from 'constants';

const ModalForm = React.forwardRef(function (
  {
    children,
    title,
    visible,
    submitForm,
    onCancel,
    initialValues,
    maskClosable,
    width,
    buttonTitle,
    requiredMark,
    onTimerFinish,
    timerStyle,
    finalMessage,
    hiddenCloseBtn = false,
    counterRef,
    modalLoading,
  },
  ref
) {
  const [form] = Form.useForm();
  const [spin, setSpin] = useState(false);
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        setSpin(true);
        submitForm(values);
      })
      .catch((errors) => {
        setSpin(false);
      });
  };
  useEffect(() => {
    setSpin(false);
    form.resetFields();
  }, [visible]);

  React.useImperativeHandle(ref, () => ({
    setSpin,
    form,
  }));
  return (
    <Modal
      centered
      maskClosable={maskClosable}
      title={title}
      visible={visible}
      onCancel={onCancel}
      width={width}
      bodyStyle={{ maxHeight: '75vh', overflow: 'auto', padding: 0 }}
      footer={false}
      closable={!hiddenCloseBtn}>
      <Form
        layout="vertical"
        form={form}
        scrollToFirstError
        initialValues={initialValues}
        onFinish={onFinish}
        requiredMark={requiredMark}>
        {children}

        <CountDownTimer
          finalMessage={finalMessage || RESEND}
          style={timerStyle || { display: 'inline' }}
          onFinish={onTimerFinish}
          minutes={9}
          ref={counterRef}
          buttonTitle={buttonTitle}
          spin={modalLoading}
        />
      </Form>
    </Modal>
  );
});

export default ModalForm;
