import PropTypes from 'prop-types';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import * as config from 'config/config';
const CaptchaForm = ({ handleVerificationSuccess, refCaptcha, onExpire }) => {
  return (
    <HCaptcha
      ref={refCaptcha}
      sitekey={config.SITEKEY_CAPTCHA}
      onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
      onExpire={() => onExpire()}
    />
  );
};

CaptchaForm.propTypes = {
  handleVerificationSuccess: PropTypes.func.isRequired,
};

export default CaptchaForm;
