import React from 'react';
import ReactDOM from 'react-dom/client';
import * as sentry from '@sentry/react';
import './globals.css';
// import 'antd/dist/antd.css'; //TODO: uncomment if raised any issue (ID-3852)
import './app.less';
import './custom.css';
import 'antd/dist/reset.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppWrapper from 'Wrapper';
import * as config from 'config/config';

if (['prod', 'on_prem'].includes(config.ENVIRONMENT)) {
  sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [],
    environment: config.ENVIRONMENT,
  });
}
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AppWrapper>
      <App />
    </AppWrapper>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
