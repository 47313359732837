import { ERROR_CODE_INVALID } from 'constants';
import { useState, useRef } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { checkCode, resendCode } from './api';
import SignInOptionsForm from './form';
import useDeleteLogin from './hooks/useDeleteLogin';
import useGetCodeMutation from './hooks/useGetCodeMutation';
import useResendCode from './hooks/useResendCode';
import useSelectWorkSpace from './hooks/useSelectWorkSpace';
import useSigninOptionStartQuery from './hooks/useSiginOptionStartQuery';

const SignInOptions = ({ query }) => {
  const navigate = useNavigate();
  const [resendForm, setResendForm] = useState();
  const [recovery, setRecovery] = useState('2');
  const [workSpaceId, setWorkSpaceId] = useState(undefined);
  const { isLoading, data } = useSigninOptionStartQuery(query);

  const { workSpaceData, workSpaceLoading, workSpaceMutate, redirectLoading } =
    useSelectWorkSpace(query);
  const { deleteMutate } = useDeleteLogin();
  const modalRef = useRef();
  const counterRef = useRef();
  const refCaptcha = useRef();
  const resend = useResendCode(query, counterRef);
  const {
    modalToggle,
    needCaptcha,
    checkCodeMutate,
    getCodeMutate,
    getCodeData,
    workSpaces,
    checkCodeError,
    getCodeLoading,
    checkCodeLoading,
    codeRedirectLoading,
  } = useGetCodeMutation(query);
  if (checkCodeError) {
    modalRef.current.setSpin(false);
    modalRef.current.form.setFields([
      {
        name: 'verifyCode',
        errors: [ERROR_CODE_INVALID],
      },
    ]);
    if (refCaptcha.current) {
      refCaptcha.current.resetCaptcha();
      modalRef.current.form.setFieldsValue({ captcha: null });
    }
  }

  const submitHandler = (values) => {
    if (workSpaces.length > 0) {
      workSpaceMutate({ workspace: workSpaceId });
    } else {
      setResendForm({ ...values });
      getCodeMutate(values);
    }
  };
  const changePageHandler = () => {
    navigate({
      pathname: '/',
      search: `?${createSearchParams(query)}`,
    });
  };
  const closeModalHandler = () => {
    deleteMutate();
    changePageHandler();
  };
  const submitCodeHandler = (values) => {
    checkCodeMutate(values);
  };
  const resendFormHandler = () => {
    if (refCaptcha.current) {
      refCaptcha.current.resetCaptcha();
      modalRef.current.form.setFieldsValue({ captcha: null });
    }
    resend.mutate(resendForm);
  };
  const workSpaceHandler = (id) => {
    setWorkSpaceId(id);
  };
  return (
    <SignInOptionsForm
      onSubmit={submitHandler}
      query={query}
      data={data}
      onSetRecovery={setRecovery}
      recovery={recovery}
      modalRef={modalRef}
      counterRef={counterRef}
      refCaptcha={refCaptcha}
      modalToggle={modalToggle}
      onCloseModal={closeModalHandler}
      onSubmitCode={submitCodeHandler}
      onResendForm={resendFormHandler}
      needCaptcha={needCaptcha}
      codeData={getCodeData}
      workSpaces={workSpaces}
      onSelectWorkSpace={workSpaceHandler}
      workSpaceId={workSpaceId}
      showSelectWorkspace={workSpaces.length > 0}
      loading={isLoading || workSpaceLoading}
      getCodeLoading={getCodeLoading}
      onChangePage={changePageHandler}
      modalLoading={resend.isLoading || checkCodeLoading}
      redirectLoading={redirectLoading || codeRedirectLoading}
    />
  );
};

export default SignInOptions;
