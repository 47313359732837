import { apiClient } from 'services';

export const fetchSiginInOption = async () => {
  const url = '/signinOption/start';
  const response = await apiClient.get(url);
  return response.data;
};

export const getCode = async (data, query) => {
  const url = '/signinOption/getCode';
  const response = await apiClient.post(
    url,
    { ...data },
    { params: { ...query } }
  );
  return response.data;
};

export const checkCode = async (data, query) => {
  const url = '/signinOption/checkCode';
  const response = await apiClient.post(
    url,
    { ...data },
    { params: { ...query } }
  );
  return response.data;
};

export const selectWorkSpace = async (data, query) => {
  const url = '/signinOption/selectWorkspace';
  const response = await apiClient.post(
    url,
    { ...data },
    { params: { ...query } }
  );
  return response.data;
};

export const deleteLogin = async () => {
  const url = '/signinOption';
  const response = await apiClient.delete(url);
  return response.data;
};

export const resendCode = async (data, query) => {
  const url = '/signinOption/resendCode';
  const response = await apiClient.post(url, data, { params: query });
  return response.data;
};
