import axios from 'axios';
import Cookies from 'js-cookie';

let session = null;
let sessionData = null;
if (window.sessionStorage) {
  session = sessionStorage.getItem('session');
} else {
  session = Cookies.get('session');
}
if (session) {
  sessionData = JSON.parse(session)?.data;
}
export const apiClient = axios.create({
  headers: {
    'content-type': 'application/json',
    session: sessionData,
  },
});
