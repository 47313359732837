import { useMutation } from 'react-query';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { sendEmail } from '../api';
import { useNotifContext } from 'store';

export const useSendEmailMutate = (query, refCaptcha) => {
  const navigate = useNavigate();
  const notif = useNotifContext();
  const { data, error, isLoading, mutate } = useMutation({
    mutationFn: (data) => sendEmail(data, query),
    onSuccess: () => {
      navigate({
        pathname: '/signinOptions',
        search: `?${createSearchParams(query)}`,
      });
    },
  });
  if (error) {
    if (refCaptcha.current) {
      refCaptcha.current.resetCaptcha();
    }
    notif.errorNotif(error?.response?.data.message);
  }
  return {
    data,
    error,
    loading: isLoading,
    mutate,
  };
};
