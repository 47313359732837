import { apiClient } from 'services';

export const fetchSession = async (query) => {
  const url = '/authentication/challenge';
  const response = await apiClient.get(url, { params: { ...query } });
  return response.data;
};

export const sendEmail = async (payload, query) => {
  const url = '/authentication/email';
  const response = await apiClient.post(
    url,
    { ...payload },
    { params: { sp: query?.sp } }
  );
  return response.data;
};

export const putAssertion = async (payload, query) => {
  const url = '/auth/users/assertion';
  const response = await apiClient.put(url, payload, { params: query });
  return response.data;
};
