import router from 'routes';
import { RouterProvider } from 'react-router-dom';
import {
  QueryCache,
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';

function App() {
  const queryClient = new QueryClient({
    mutationCache: new MutationCache({
      onError: (error) => {
        if (error.response) {
          const statusCode = error.response ? error.response.status : null;
          const message = error.response?.data
            ? error.response.data.message
            : '';
          if (
            statusCode === 403 &&
            message.toString().includes('Session expired')
          ) {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      },
    }),
    queryCache: new QueryCache({
      onError: (error) => {
        if (error.response) {
          const statusCode = error.response ? error.response.status : null;
          const message = error.response?.data
            ? error.response.data.message
            : '';
          if (
            statusCode === 403 &&
            message.toString().includes('Session expired')
          ) {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      },
    }),
  });
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
