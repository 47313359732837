import { useState } from 'react';

const { useMutation } = require('react-query');
const { getCode, checkCode } = require('../api');

const useGetCodeMutation = (query) => {
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [modalToggle, setModalToggle] = useState(false);
  const [needCaptcha, setNeedCaptcha] = useState(false);
  const [workSpaces, setWorkSpaces] = useState([]);
  const {
    data: getCodeData,
    error: getCodeError,
    isLoading: getCodeLoading,
    mutate: getCodeMutate,
  } = useMutation({
    mutationFn: (data) => getCode(data, query),
    onSuccess: (data) => {
      setModalToggle(true);
      setTimeout(() => {
        if (data.needCaptcha) {
          setNeedCaptcha(true);
        }
      }, 500);
    },
  });

  const {
    data: checkCodeData,
    error: checkCodeError,
    isLoading: checkCodeLoading,
    mutate: checkCodeMutate,
  } = useMutation({
    mutationFn: (data) => checkCode(data, query),
    onSuccess: (data) => {
      setModalToggle(false);
      if (data?.redirectURL) {
        setRedirectLoading(true);
        window.location.replace(data.redirectURL);
      } else if (data?.workspaces) {
        setWorkSpaces(data.workspaces);
      }
    },
  });

  return {
    getCodeData,
    getCodeError,
    getCodeLoading,
    getCodeMutate,
    needCaptcha,
    modalToggle,
    checkCodeMutate,
    workSpaces,
    checkCodeError,
    checkCodeLoading,
    codeRedirectLoading: redirectLoading,
  };
};

export default useGetCodeMutation;
