import LoginForm from './LoginForm';

const StepTwo = ({ loading, form, refCaptcha, prev }) => {
  return (
    <LoginForm
      form={form}
      loading={loading}
      refCaptcha={refCaptcha}
      prev={prev}
    />
  );
};

export default StepTwo;
