import useNotif, { errorNotif } from 'component/ui/notif';
import { useQuery } from 'react-query';
import { apiClient } from 'services';
import { fetchSession } from '../api';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { notification } from 'antd';
import { useNotifContext } from 'store';
export const useGetSessionQuery = (query) => {
  const notif = useNotifContext();

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['get-session', query],
    queryFn: () => fetchSession(query),
    staleTime: 3 * 3600 * 1000, //3 h ,
    retry: 3,
  });

  if (error) {
    notif.errorNotif(error.response?.data?.message);
  } else if (data !== null && data != undefined) {
    if (window?.sessionStorage) {
      window.sessionStorage.setItem('session', JSON.stringify(data.session));
      apiClient.defaults.headers['session'] = data.session.data;
    } else {
      Cookies.set('session', JSON.stringify(data.session));
      apiClient.defaults.headers['session'] = data.session.data;
    }
  }

  return {
    data,
    error,
    loading: isLoading,
    refetch,
  };
};
