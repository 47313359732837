const { Outlet } = require('react-router-dom');

const Main = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#ECECEC',
        overflow: 'auto',
      }}>
      <Outlet />
    </div>
  );
};

export default Main;
